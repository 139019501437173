<template>
  <div>
    <v-container fluid class="py-8 position-relative">
      <Loading v-if="loading"></Loading>
      <Notify ref="myNotify" :details="message"></Notify>
      <!-- <h5 class="text-h3 text-typo font-weight-bold ms-4 mb-3">
        {{ $t("user.Profile") }}
      </h5> -->

      <div class="mt-10">
        <div id="stepWizard" v-if="tab_inqueries.length > 0">
          <ul class="nav-wizard">
            <li
              v-for="(item, i) in tab_inqueries"
              :key="i"
              @click="activeity(i)"
              :class="[activeTab == i ? 'active' : '']"
              class="tab-link"
            >
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </div>
        <div class="tabs_content mt-10">
          <v-card
            v-for="(item, i) in tab_inqueries"
            :key="i"
            v-if="activeTab == i"
            class="card-shadow card-padding pt-10"
          >
            <v-form ref="form" lazy-validation>
              <v-row>
                <v-col
                  class="pt-0"
                  v-for="(inquery, index) in item.get_inquiries"
                  :key="index"
                  cols="6"
                >
                  <v-row>
                    <v-col cols="12" sm="10">
                      <div class="position-relative">
                        <label
                          class="text-xs text-typo font-weight-bolder ms-1"
                          >{{ inquery.inquery_lang }}</label
                        >
                        <span
                          class="strick text-red mx-2"
                          v-if="inquery.register_form_inquiry.is_required == 1"
                          >*</span
                        >
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="2"
                      v-if="
                        inquery.register_form_answers != null &&
                        inquery.register_form_answers.is_file == 1 &&
                        inquery.register_form_answers.answer != null
                      "
                    >
                      <a
                        target="_blank"
                        class="text-decoration-none"
                        :href="inquiries_data[i].arr[index].file"
                      >
                        <v-btn
                          color="#fff"
                          class="
                            text-dark
                            font-weight-bolder
                            py-6
                            px-5
                            shadow-0
                          "
                          small
                          simple
                        >
                          <v-icon size="18" class="rotate-180"
                            >fas fa-file-pdf text-lg me-1</v-icon
                          >
                          {{ $t("ticket.file") }}
                        </v-btn>
                      </a>
                    </v-col>
                  </v-row>
                  <div
                    class="position-relative"
                    v-if="inquery.inputs.type == 'file'"
                  >
                    <v-file-input
                      v-model="inquiries_data[i].arr[index].answer"
                      show-size
                      counter
                      chips
                    ></v-file-input>
                  </div>
                  <!-- :rules="
                      inquery.register_form_inquiry.is_required == 1
                        ? [(v) => !!v || $t('form.Item is required')]
                        : []
                    " -->
                  <div class="position-relative" v-else>
                    <v-text-field
                      :type="inquery.inputs.type"
                      outlined
                      color="rgba(0,0,0,.6)"
                      class="
                        font-size-input
                        placeholder-lighter
                        text-light-input
                      "
                      v-model="inquiries_data[i].arr[index].answer"
                    >
                    </v-text-field>
                  </div>
                  <!-- :rules="
                      inquery.register_form_inquiry.is_required == 1
                        ? [(v) => !!v || $t('form.Item is required')]
                        : []
                    " -->
                  <!-- </v-text-field> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col :class="classLang" cols="12">
                  <v-btn
                    class="btn-primary mw-80"
                    color="white"
                    :loading="loader_form"
                    text
                    @click="save_form()"
                  >
                    <span slot="loader">
                      <v-progress-circular
                        style="width: 20px; height: 20px"
                        indeterminate
                        color="white"
                      ></v-progress-circular>
                    </span>
                    {{ $t("form.save") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import Form from "../../Components/Old/Form.vue";
import Notify from "../../Components/Old/Notify.vue";
import Loading from "../../Components/Loading.vue";
import customerService from "../../../services/customer.service";
export default {
  name: "Application-Form",
  components: {
    Form,
    Loading,
    Notify,
  },
  data() {
    return {
      activeTab: 0,
      totalSteps: 7,
      loading: false,
      message: {
        msg: null,
        type: null,
      },
      loader_form: false,
      tab_inqueries: [],
      inquiries_data: [],
      finish_form: false,
    };
  },
  computed: {
    classLang() {
      return {
        "text-left": this.$vuetify.rtl,
        "text-right": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    get_form() {
      this.loading = true;
      return customerService.get_form_tabs_inquery().then(
        (response) => {
          this.tab_inqueries = response.data;
          for (let i = 0; i < this.tab_inqueries.length; i++) {
            const element = this.tab_inqueries[i];
            // if (i == this.activeTab) {
            var newarray = { arr: [] };
            for (let y = 0; y < element.get_inquiries.length; y++) {
              const elementy = element.get_inquiries[y];

              var is_file = 0,
                id = null,
                answer = null,
                file = null;
              if (elementy.inputs.type == "file") {
                is_file = 1;
              }
              if (elementy.register_form_answers != null) {
                id = elementy.register_form_answers.id;
                if (elementy.register_form_answers.is_file == 0) {
                  answer = elementy.register_form_answers.answer;
                } else {
                  file = elementy.register_form_answers.answer;
                }
              }
              newarray.arr.push({
                answer: answer,
                tab_id: elementy.pivot.tab_id,
                is_file: is_file,
                inquiry_id: elementy.pivot.inquiry_id,
                id: id,
                file: file,
              });
              // }
            }
            this.inquiries_data.push(newarray);
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          // console.log(error);
        }
      );
    },
    nextStep() {
      this.activeTab++;
    },
    activeity(val) {
      this.activeTab = val;
    },
    save_form() {
      this.loader_form = true;
      // if (this.validate()) {
      let formData = new FormData();
      var inqueries = this.inquiries_data[this.activeTab].arr;
      for (let i = 0; i < inqueries.length; i++) {
        const element = inqueries[i];
        formData.append(`id[${i}]`, element.id);
        formData.append(`answer[${i}]`, element.answer);
        formData.append(`tab_id[${i}]`, element.tab_id);
        formData.append(`is_file[${i}]`, element.is_file);
        formData.append(`inquiry_id[${i}]`, element.inquiry_id);
      }
      if (this.finish_form) {
        formData.append("finish_form", true);
      }
      if (this.activeTab == this.tab_inqueries.length - 1) {
        formData.append("last_tab", true);
        this.finish_form = true;
      }
      return customerService.add_form_register_inquery(formData).then(
        (response) => {
          this.loader_form = false;
          // console.log(response);
          this.message = {
            msg: response.data.message,
            type: "Success",
          };
          var new_answer = response.data.data.answer;
          for (let z = 0; z < inqueries.length; z++) {
            const element = inqueries[z];
            for (let m = 0; m < new_answer.length; m++) {
              const elementm = new_answer[m];
              if (
                elementm.tab_id == element.tab_id &&
                elementm.inquiry_id == element.inquiry_id
              ) {
                element.id = elementm.id;
                if (elementm.is_file == 1) {
                  element.answer = null;
                  element.file = elementm.answer;
                  this.tab_inqueries[this.activeTab].get_inquiries[
                    z
                  ].register_form_answers = elementm;
                }
              }
            }
          }
          if (this.activeTab != this.tab_inqueries.length - 1) {
            // not last tab
            if (this.finish_form) {
              console.log(response.data.data.register_form);
              if (response.data.data.register_form == 0) {
                this.$router.push({ name: "Cycles" });
              } else {
                this.nextStep();
              }
            } else {
              this.nextStep();
            }
          } else {
            // last tab
            if (response.data.data.hasOwnProperty("register_form")) {
              if (response.data.data.register_form == 1) {
                this.message = {
                  msg: this.$i18n.t("Please fill out the required inquiries"),
                  type: "Warning",
                };
              } else {
                this.$router.push({ name: "Cycles" });
              }
            }
          }
        },
        (error) => {
          // console.log(error);
          this.loader_form = false;

          if (error.response.status != 401) {
            this.message = {
              msg: this.$i18n.t("general.there is problem"),
              type: "Danger",
            };
          }
        }
      );
      // } else {
      //   this.loader_form = true;
      // }
    },
  },
  mounted() {
    this.get_form();
    document.title = this.$i18n.t("sidebar.Register Form");
  },
};
</script>
<style lang="css">
hr.horizontal {
  opacity: 0.5;
}
</style>
